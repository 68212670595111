<template>
  <div class="d-inline-flex flex-column pb-2 pr-2">
    <h6 :for="uuid" v-html="labelHtml" />
    <input
      :type="type"
      :value="modelValue"
      :required="required"
      :id="uuid"
      :disabled="readOnly"
      :list="list"
      :class="classes"
      @input="update($event.target.value)"
    />
    <p v-show="validation.errorMessage" v-text="validation.errorMessage"></p>
  </div>
</template>

<script>
import { useCounterStore } from '@/stores/counter';
import { useOptionsStore } from '@/stores/options';
import { mapStores } from 'pinia';
export default {
  props: {
    modelValue: { required: true },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    list: {
      type: String,
      required: false
    },
    config: {
      type: Object,
      default: () => ({ type: "text" })
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: Number,
      default: 0
    },
    validation: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: "text"
    },
    validations: {
      type: Object,
      default: () => ({})
    },
    classes: {
      type: String,
      default: ""
    },
    watchCounter: {
      type: Boolean,
      default: false
    },
    numItem:{
      type: Number,
      default: 0
    },
    optionToWatch: {
      type: Object,
    },
  },
  computed: {
    labelHtml() {
      const requiredHtml =
        this.validations._exclusive && this.validations._exclusive.required
          ? ' <span class="label--required">*</span>'
          : "";
      return this.label + requiredHtml;
    },
    ...mapStores(useCounterStore),
    ...mapStores(useOptionsStore)
  },
  methods: {
    update(value) {
      this.$emit("update:modelValue", value);
    }
  },
  created (){
    if(this.watchCounter){
      this.counterStore.$onAction(
        ({
          // eslint-disable-next-line no-unused-vars
          name,
          // eslint-disable-next-line no-unused-vars
          store, 
          // eslint-disable-next-line no-unused-vars
          args, 
          after, 
          onError
        }) => {
        onError((error) => {
          console.log("Erreur: ", error.message);
        })
        after(() => {
          if(['increment', 'decrement', 'setCount'].indexOf(name) != -1){
            this.$el.querySelector('input').removeAttribute('disabled');
            if(this.numItem > this.counterStore.getCount){
              this.$el.querySelector('input').value = null;
              this.$el.querySelector('input').setAttribute('disabled', this.numItem > this.counterStore.getCount);
              this.update(this.$el.querySelector('input').value);
            }
          }
        })
      })
    }
    if(this.optionToWatch){
      this.optionsStore.$onAction(
        ({
        name,
        // eslint-disable-next-line no-unused-vars
        store, 
        // eslint-disable-next-line no-unused-vars
        args, 
        after, 
        onError}) => {
          onError((error) => {
            console.log("Erreur: ", error.message);
          })
          after(() => {
            if(['watchOption'].indexOf(name) != -1 && args[0] === this.optionToWatch.keyToWatch && args[1] === this.optionToWatch.paramToWatch && args[2] === this.optionToWatch.valueToWatch){
              this.update(document.querySelectorAll("#"+this.list+" option")[this.numItem-1].value);
            }
          })
        }
      )
    }
  },
};
</script>
<style scoped>
  input{
    width: 110px;
  }
</style>