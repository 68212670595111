<template>
  <div class=" pb-2">
    <div class="d-inline-flex flex-column">
      <h6 :for="'element'+uuid" v-html="labelHtml"/>
      <button
          :id="'element'+uuid"
          :value="modelValue"
          class="p-1 btn-outline-dark btn m-2"
          @change="update($event.target.value)"
          @click="openPopin()"
      >
      <img v-if="options[parseInt(modelValue)].url" :src="options[parseInt(modelValue)].url" :class="classImg">
      <div v-else class="d-inline-flex flex-column text-center">
        {{ options[parseInt(modelValue)].description }}
      </div>
      </button>
    </div>
    <div :id="'popinForm'+uuid" tabindex="-1" role="dialog" class="d-none w-100 popinForm">
      <div>
        <div class="d-flex justify-content-between align-items-center">
          <input type="text" placeholder="Recherche par id ou description" @input="filtrer($event.target.value)" class="m-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" :id="'switch'+uuid" checked @click="showAll()">
            <label class="custom-control-label" :for="'switch'+uuid">Actifs uniquement</label>
          </div>
        </div>
        <div class="d-inline-flex flex-wrap items-container w-100">
          <div
            v-for="option in options"
            :key="option.id"
            :class="'col-'+rows+' py-1 text-center item-container '+(option.available?'':'not-showed')"
          >
            <div :class="option.name+option.id+' select-container '+(option.available?'available':'')">
              <input type="radio" :id="'item'+option.id+uuid" :name="option.name" class="d-none" @click="changeItem(option.id)" :data-event="option['dataToChange']" :value="option.id">
              <label :for="'item'+option.id+uuid" :class="classOptions+' align-items-center w-100'">
                <img v-if="option.url" :src="option.url" :class="classImg">
                <div class="flex-grow-1">{{ option.id }} - {{ option.description }}</div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useOptionsStore } from '@/stores/options';
import { mapStores } from 'pinia';
export default {
  props: {
    modelValue: {
      required: true,
      type: [String, Number],
      default: model => Object.values(model.options)[0].id
    },
    required: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
    label: {
      type: String,
      required: true,
      default: " "
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    uuid: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "text",
    },
    classOptions: {
      type: String,
      default: "d-inline-flex",
    },
    classImg: {
      type: String,
      default: "d-inline-flex",
    },
    keyToChange: {
      type: String,
      default: "",
    },
    dataKeyToChange: {
      type: String,
    },
    options: { type: Object, required: true },
    onchange: { type: String, required: false}
  },
  mounted: function() {
    document.querySelector('#item'+parseInt(this.modelValue)+this.uuid).setAttribute('checked', 'true');
    this.selectLogo(this.modelValue);
    this.changeItem(this.modelValue);
  },
  computed: {
    labelHtml() {
      return this.label;
    },
    getDataName() {
      return "data-"+this.keyToChange;
    },
    ...mapStores(useOptionsStore)
  },
  methods: {
    update(value) {
      this.$emit("update:modelValue", value); 
    },
    openPopin(){
      document.querySelector('#popinForm'+this.uuid.toString()).classList.toggle('d-none');
    },
    filtrer(filtre){
      document.querySelectorAll("#popinForm"+this.uuid+" .item-container").forEach(container => {
        container.classList.remove('not-a-match');
        if(!(container.querySelector("label div").textContent.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/gu, '').includes(filtre.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/gu, ''))) && filtre.length > 0){
          container.classList.add('not-a-match');
        }
      })
    },
    changeItem(element_id){
      this.update(element_id);
      let keysToChange = document.querySelector("#item"+parseInt(element_id)+this.uuid).dataset?.event;
      if(keysToChange != undefined){
        keysToChange = JSON.parse(keysToChange);
        for(const [key, value] of Object.entries(keysToChange)) {
          this.optionsStore.setOption(key, value);
        }
      }
      this.evaluateChange(element_id);
    },
    // eslint-disable-next-line no-unused-vars
    evaluateChange(element_id){ //element_id peut être utilisé dans le onchange
      if(this.onchange){
        eval(this.onchange)
      }
    },
    selectLogo(element_id){
      if(document.querySelector("#popinForm"+this.uuid+" .select-container.selected")){
        document.querySelector("#popinForm"+this.uuid+" .select-container.selected").classList.remove('selected');
      }
      document.querySelector("#item"+parseInt(element_id)+this.uuid).parentNode.classList.add('selected');
    },
    showAll(){
      document.querySelectorAll("#popinForm"+this.uuid+" .item-container .select-container:not(.available)").forEach(container => {
        container.parentNode.classList.toggle("not-showed");
      })
    }
  },
  watch:{
    modelValue(){
      this.selectLogo(this.modelValue);
    }
  }
};
</script>
<style scoped>
  img{
    height: 45px;
    max-height: 200px;
    max-width:180px;
  }
  .items-container{
    max-height: 300px;
    overflow: scroll;
  }
  .item-container{
    padding: 5px;
  }
  .select-container{
    background-color: rgb(240, 240, 240);
    font-size: 12px;
    padding : 10px 0 10px 0;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0);
  }
  .select-container.available{
    background-color: #ebfdf0;
  }
  .select-container.selected{
    border: 1px solid #858585;
  }
  .select-container label{
    margin:0;
  }
  .popinForm{
    background-color: rgb(255,255,255);
    border-radius: 5px;
    box-shadow: inset 0px 0px 8px 1px rgb(240,240,240);
    padding: 5px 10px;
    margin-top: 10px;
  }
  .custom-control-input:checked~.custom-control-label::before{
    border-color: lightgreen;
    background-color: lightgreen;
  }
  .custom-control-input:focus:not(:checked)~.custom-control-label::before{
    border-color: lightgreen;
  }
  .custom-control-input:focus~.custom-control-label::before{
    box-shadow: 0 0 0 0.2rem rgba(144, 238, 144, 0.25);
  }
  .not-a-match{
    display: none;
  }
  .not-showed{
    display:none;
  }
</style>