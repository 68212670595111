<template>
  <div :id="'id'+uuid">
    <button type="button" :class="classButtonPlus" @click="clickAdd()">{{ labelPlus }}</button>
    <button type="button" :class="classButtonMoins" @click="clickRemove()">{{ labelMoins }}</button>
  </div>
</template>

<script>
import { useCounterStore } from '@/stores/counter';
import { mapStores } from 'pinia';
export default {
  props: {
    modelValue: { required: true },
    required: {
      type: Boolean,
      default: false
    },
    uuid: { type: Number, required: true},
    classButtonPlus: { type: String, required: false},
    classButtonMoins: { type: String, required: false},
    labelPlus: { type: String, required: true }, 
    labelMoins: { type: String, required: true }, 
  },
  methods:{
    clickAdd(){
      this.counterStore.increment();
    },
    clickRemove(){
      this.counterStore.decrement();
    },
  },
  computed: {
    ...mapStores(useCounterStore)
  }
}
</script>