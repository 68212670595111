import { defineStore } from 'pinia'

export const useCounterStore = defineStore('counter', {
    state: () => ({
      count: 0,
      max_value: 6,
      not_nb: 0,
    }),
    getters: {
      getCount: (state) => state.count,
      getMaxValue: (state) => state.max_value,
      getNotNb: (state) => state.not_nb,
    },
    actions: {
      decrement() {
        do {
            this.count--;
        }while((this.not_nb.indexOf(this.count) != -1 && this.count > 1) || this.count > this.max_value);
        if(this.count < 1 || this.not_nb.indexOf(this.count) != -1){
            this.increment();
        }
        return this.count;
      },
      increment() {
        do {
            this.count++;
        }while(this.not_nb.indexOf(this.count) != -1 && this.count < this.max_value);
        if(this.count > this.max_value || this.not_nb.indexOf(this.count) != -1){
            this.decrement();
        }
        return this.count;
      },
      setMaxValue(value){
        this.max_value = value;
      },
      setNotNb(value){
        this.not_nb = value;
      },
      fill(not_nb, max_value){
        this.setMaxValue(max_value);
        this.setNotNb(not_nb);
        if(this.count > this.max_value){
            this.decrement();
        }
        if(this.not_nb.indexOf(this.count) != -1){
            this.increment();
        }
      },
      setCount(value){
        this.count = value;
        return this.count;
      },
      
    },
  })