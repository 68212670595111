<template>
  <div class="d-inline-flex flex-column pb-2 pr-2">
    <input
      :type="type"
      :value="modelValue"
      :required="required"
      :id="'id'+uuid"
      :disabled="readOnly"
      @input="update($event.target.value)"
    />
    <p v-show="validation.errorMessage" v-text="validation.errorMessage"></p>
  </div>
</template>

<script>
import { useOptionsStore } from '@/stores/options';
import { mapStores } from 'pinia';
export default {
  props: {
    modelValue: { required: true },
    required: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: Number,
      default: 0
    },
    eventToListen:{
      type:String,
      default : ""
    },
    type: {
      type: String,
      default: "hidden"
    },
    validation: {
      type: Object,
      default: () => ({})
    },
    keyToWatch: {
      type: String
    }
  },
  created (){
    this.optionsStore.$onAction(
      ({
        name,
        // eslint-disable-next-line no-unused-vars
        store, 
        args, 
        after, 
        onError
      }) => {
        after((result) => {
          if(name === "setOption" && args[0] === this.keyToWatch){
              document.querySelector('#id'+this.uuid.toString()).value = result;
              this.update(result);
            }
        })
        onError((error) => {
          console.log("Erreur: ", error.message);
        })
      })
  
  },
  methods: {
    update(value) {
      this.$emit("update:modelValue", value);
    },
  },
  computed: {
    ...mapStores(useOptionsStore)
  }
};

</script>
