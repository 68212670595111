<template>
  <div class="align-items-start flex-column pb-2">
    <h6 :for="'id'+uuid" v-html="labelHtml" />
    <select
      :value="modelValue"
      :required="required"
      :id="'id'+uuid"
      @input="changeItem($event.target.value)"
    >
      <option v-if="!disableNoSelection">-</option>
      <option
        v-for="option in options"
        :key="option.id"
        :value="option.id"
        :selected="option.id === modelValue"
      >
        {{ option.beautify }}
      </option>
    </select>
    <div v-for="option in options" :key="option.id">
      <div v-if="option.id === modelValue && option.info">{{ option.info }}</div>

    </div>
  </div>
</template>

<script>
import { useCounterStore } from '@/stores/counter';
import { useOptionsStore } from '@/stores/options';
import { mapStores } from 'pinia';
export default {
  props: {
    modelValue: { required: true },
    required: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
    uuid: {
      type: Number,
      default: 0,
    },
    label: { type: String, required: true },
    options: { type: Array, required: true },
    disableNoSelection: { type: Boolean, default: false },
    validations: {
      type: Object,
      default: () => ({}),
    },
    initCounter: {
      type: Boolean,
      default: false
    }
  },
  mounted: function(){
    this.options.forEach(option => {
      for (const [attr, value] of Object.entries(option)){
        if(['id', 'beautify'].indexOf(attr) == -1){
          document.querySelector('#id'+this.uuid+' option[value="'+option.id+'"]').setAttribute('data-'+attr, value);
        }
      }
    });
    if(this.initCounter){
      const selectedOption = document.querySelector('#id'+this.uuid+' option:checked');
      this.counterStore.setMaxValue(selectedOption.dataset.max_value);
      this.counterStore.setNotNb(selectedOption.dataset.not_nb);
    }
  },
  computed: {
    labelHtml() {
      const requiredHtml =
        this.validations._exclusive && this.validations._exclusive.required
          ? ' <span class="label--required">*</span>'
          : "";
      return this.label + requiredHtml;
    },
    ...mapStores(useCounterStore),
    ...mapStores(useOptionsStore)
  },
  methods: {
    update(value) {
      return new Promise((resolve) => { 
        this.$emit("update:modelValue", value);
        resolve();
      });
    },
    async changeItem(element_id) {
      await this.update(element_id);
      const checkedOption = document.querySelector('#id'+this.uuid+' option:checked');
      if(this.initCounter){
        this.counterStore.fill(checkedOption.dataset.not_nb, checkedOption.dataset.max_value);
      }
      if(!parseInt(checkedOption.dataset.personnalisable)){
        this.counterStore.setCount(checkedOption.dataset.max_value);
        this.optionsStore.watchOption(this.$attrs.model, 'personnalisable', 'false');
      }
    },
  },
};
</script>
